<template>
  <div>
    <h1 class="mb-1">
      Solar Investment Calculator
    </h1>
    <p>Find out how much you could make from an investment into one of our solar project.</p>

    <v-row>
      <v-col cols="12" md="5">
        <v-card outlined min-height="120px">
          <v-form v-model="formValid">
            <v-card-title class="pb-1">
              Project
            </v-card-title>
            <v-card-text>
              <p>
                Select a solar project.
              </p>

              <v-combobox
                v-model="project"
                :items="projects"
                label="Project"
                placeholder="Select project"
                hide-details="auto"
                item-text="title"
                item-value="address"
                class="mt-4"
                outlined
                rounded
                return-object
              >
                <template #append-outer>
                  <tooltip class="mr-n2">
                    <span>Data from the selected project will be used in calculations.</span>
                  </tooltip>
                </template>
                <template #selection>
                  <v-chip
                    :to="{ name: 'project', params: { slug: project.slug }}"
                  >
                    <span class="text-subtitle-2">
                      {{ project.title }}
                    </span>
                    <span class="mx-1"> • </span>
                    <span :class="status[project.status].color + '--text'">
                      {{ status[project.status].text }}
                    </span>
                  </v-chip>
                </template>
              </v-combobox>
            </v-card-text>
            <v-card-title class="pb-1">
              Investment
            </v-card-title>
            <v-card-text>
              <p>
                Fill in your investment details.
              </p>

              <v-checkbox
                v-if="showUSTaxPayer"
                v-model="USTaxPayer"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  I'm a US tax payer
                  <tooltip class="ml-1">
                    <span>US tax payers can claim Investment Tax Credits (ITC) for investment into US projects.</span>
                  </tooltip>
                </template>
              </v-checkbox>

              <v-checkbox
                v-if="showUSAccreditedInvestor"
                v-model="USAccreditedInvestor"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  I'm a US accredited investor
                  <tooltip class="ml-1">
                    <span>US tax payers need to be accredited investors to invest in US projects.</span>
                  </tooltip>
                </template>
              </v-checkbox>

              <v-text-field
                v-if="showPassiveIncomeTaxBill"
                v-model="passiveIncomeTaxBill"
                :prepend-inner-icon="icons.mdiCurrencyUsd"
                label="Passive income tax bill"
                hide-details="auto"
                outlined
                rounded
                class="mt-4"
                type="number"
                value="0"
                :disabled="disablePassiveIncomeTaxBill"
                @input="passiveIncomeTaxBillChanged"
              >
                <template #append-outer>
                  <tooltip class="mr-n2">
                    <span>Passive income is earnings from a rental property, limited partnership, or other business in which a person is not actively involved.</span>
                  </tooltip>
                </template>
              </v-text-field>

              <v-text-field
                v-model="investmentAmount"
                :prepend-inner-icon="icons.mdiCurrencyUsd"
                label="Investment Amount"
                hide-details="auto"
                outlined
                rounded
                class="mt-4 mb-2"
                type="number"
                value="0"
                :disabled="disableInvestmentAmount"
                @input="investmentAmountChanged"
              >
                <template #append-outer>
                  <tooltip class="mr-n2">
                    <span>The amount of money to be invested.</span>
                  </tooltip>
                </template>
              </v-text-field>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card outlined min-height="120px">
          <v-card-title class="pb-1">
            Projected Returns
          </v-card-title>
          <v-card-text>
            <p class="mb-3">
              These projections are based on project data, however actual performance might differ.
            </p>

            <v-row>
              <v-col cols="12" sm="6">
                <v-chip class="metric d-flex mt-2">
                  <span class="label">Contract type:</span>{{ projectType }}
                  <v-spacer></v-spacer>
                  <tooltip class="mr-n2">
                    <p>
                      <span class="text-subtitle-2">Partnership Flip</span><br>
                      Partnership flip contracts are used to raise tax equity in the US renewable energy market.
                    </p>
                    <p>
                      <span class="text-subtitle-2">Sale Leaseback</span><br>
                      Sale leaseback contracts allow the owner to sell an asset to an investor and then lease back that same asset from the investor.
                    </p>
                  </tooltip>
                </v-chip>
                <v-chip class="metric d-flex mt-2">
                  <span class="label">Token amount:</span>{{ tokenAmount || 0 | toNumber }}
                  <v-spacer></v-spacer>
                  <tooltip class="mr-n2">
                    <span>The number of tokens that will be issued for the given investment amount.</span>
                  </tooltip>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="6">
                <v-chip class="metric d-flex mt-2">
                  <span class="label">IRR:</span>{{ IRR | toNumber }}%
                  <v-spacer></v-spacer>
                  <tooltip class="mr-n2">
                    <span>The Internal Rate of Return (IRR) of the selected project.</span>
                  </tooltip>
                </v-chip>
                <v-chip class="metric d-flex mt-2">
                  <span class="label">Tax credit:</span>{{ taxCredits | toCurrency }}
                  <v-spacer></v-spacer>
                  <tooltip class="mr-n2">
                    <span>The amount of money that can be claimed back from the IRS by US tax payers.</span>
                  </tooltip>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <highcharts :options="chartOptions" style="height: 300px"></highcharts>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCurrencyUsd } from '@mdi/js'
import { Chart } from 'highcharts-vue'
import colors from 'vuetify/lib/util/colors'
import Tooltip from '@/components/Tooltip.vue'

export default {
  components: {
    highcharts: Chart,
    Tooltip,
  },
  setup() {
    return {
      icons: {
        mdiCurrencyUsd,
      },
    }
  },
  data() {
    return {
      formValid: false,
      project: null,
      USTaxPayer: false,
      USAccreditedInvestor: false,
      passiveIncomeTaxBill: null,
      investmentAmount: null,
      depreciationTotal: 1,
      macrsTable: {
        1: 0.2,
        2: 0.32,
        3: 0.192,
        4: 0.1152,
        5: 0.1152,
        6: 0.0576,
      },
      status: {
        1: { text: 'Comming Soon', color: 'primary' },
        2: { text: 'Available Now', color: 'info' },
        3: { text: 'Under Construction', color: 'success' },
        4: { text: 'Generating Power', color: 'warning' },
      },
      chartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
          events: {
            render: function() {
              this.reflow()
            },
          },
        },
        title: {
          text: 'Cumulative Revenue',
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>${point.y:,.2f}<br/>Total: ${point.stackTotal:,.2f}</b>',
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          title: {
            text: 'Date',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        yAxis: {
          min: 0,
          max: 10000,
          title: {
            text: 'Total ($)',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            shadow: false,
            stacking: 'normal',
          },
        },
        legend: {
          enabled: false,
        },
        colors: [
          colors.blue.lighten2,
          colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [
          {
            name: 'Cash Flow',
            data: [],
          },
          {
            name: 'MACRS Depreciation',
            data: [],
          },
          {
            name: 'Investment Tax Credit',
            data: [],
          },
        ],
      },
    }
  },
  computed: {
    projects() {
      return this.$store.getters.projects
    },
    projectType() {
      return this.project ? this.project.contractType : 'NA'
    },
    projectIsPartnershipFlip() {
      return this.project && this.project.contractType === 'Partnership Flip'
    },
    projectIsInUS() {
      return this.project && this.project.country === 'USA'
    },
    bonusDepreciation() {
      return this.project && this.project.bonusDepreciation
    },
    showUSTaxPayer() {
      return this.project && this.projectIsPartnershipFlip
    },
    showUSAccreditedInvestor() {
      return this.project && this.projectIsPartnershipFlip && this.USTaxPayer
    },
    showPassiveIncomeTaxBill() {
      return this.project && this.projectIsPartnershipFlip && this.USTaxPayer
    },
    disablePassiveIncomeTaxBill() {
      return this.project && this.projectIsPartnershipFlip && this.USTaxPayer && !this.USAccreditedInvestor
    },
    disableInvestmentAmount() {
      return this.project && this.projectIsPartnershipFlip && this.USTaxPayer && !this.USAccreditedInvestor
    },
    tokenAmount() {
      var amount = 0
      if (this.project && this.investmentAmount) {
        if (this.projectIsPartnershipFlip) {
          amount = ((this.investmentAmount * this.project.TEPPreFlipShare) / this.project.TEPInvestmentShare).toFixed(2)
        } else {
          amount = this.investmentAmount
        }
      }
      return amount
    },
    IRR() {
      return this.project ? this.project.IRR : 0
    },
    depreciation() {
      return this.project && this.projectIsInUS && this.investmentAmount && this.USTaxPayer && this.USAccreditedInvestor ? (this.investmentAmount * this.depreciationTotal) : 0
    },
    taxCredits() {
      return this.project && this.projectIsInUS && this.investmentAmount && this.USTaxPayer && this.USAccreditedInvestor && this.passiveIncomeTaxBill ? (Math.min(this.investmentAmount * 0.26, this.passiveIncomeTaxBill)) : 0
    },
  },
  watch: {
    'project.slug': function(slug) {
      if (slug !== this.$route.params.slug) {
        this.$router.push({ name: 'calculator', params: { slug: slug } })
        this.setCashflowData(this.investmentAmount)
        this.setDepreciationData(this.depreciation)
        this.setTaxCreditsData(this.taxCredits)
      }
    },
    investmentAmount: function(value) {
      this.chartOptions.yAxis.max = value ? null : 10000

      if (value) {
        this.chartOptions.yAxis.plotLines = [
          {
            value: value,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: 'Investment',
              style: {
                color: '#ffffffde',
              },
            },
          },
        ]
      } else {
        this.chartOptions.yAxis.plotLines = []
      }
      this.setCashflowData(value)
    },
    depreciation: function(value) {
      this.setDepreciationData(value)
    },
    taxCredits: function(value) {
      this.setTaxCreditsData(value)
    },
  },
  mounted() {
    this.chartOptions.series[0].data = []
    this.chartOptions.series[1].data = []
    this.chartOptions.series[2].data = []

    if (this.$route.params.slug) {
      this.project = this.$store.getters.getProject(this.$route.params.slug)
    }
  },
  methods: {
    passiveIncomeTaxBillChanged(value) {
      this.investmentAmount = Math.ceil(value / 0.26)
    },
    investmentAmountChanged(value) {
      this.passiveIncomeTaxBill = Math.floor(value * 0.26)
    },
    setCashflowData(investmentAmount) {
      this.chartOptions.series[0].data = []

      var investmentRatio = investmentAmount / this.project.cost
      var cashflow = (this.project.avgAnnualkWh * this.project.tarrif * investmentRatio) / 4
      var escalationFactor = this.project.escalationFactor / 4
      var cumulativeCashflow = 0

      var quarter = 1
      var startDate = this.project.connectionDate
      var startMonth = startDate.getMonth()

      while (quarter <= 80) {
        var date = new Date(startDate)
        date.setHours(2)
        date.setMonth(startMonth + ((quarter - 1) * 3))

        if (this.projectIsPartnershipFlip) {
          if (quarter <= 24) {
            cumulativeCashflow += cashflow * (this.project.TEPPreFlipShare / this.project.TEPInvestmentShare)
          } else {
            cumulativeCashflow += cashflow * (this.project.TEPPostFlipShare / this.project.TEPInvestmentShare)
          }
        } else {
          cumulativeCashflow += cashflow
        }

        cashflow *= (1 + escalationFactor)

        this.chartOptions.series[0].data.push([date.getTime(), cumulativeCashflow])
        quarter++
      }
    },
    setTaxCreditsData(value) {
      this.chartOptions.series[2].data = []

      var quarter = 1
      var startDate = this.project.connectionDate
      var startMonth = startDate.getMonth()

      while (quarter <= 80) {
        var date = new Date(startDate)
        date.setHours(2)
        date.setMonth(startMonth + ((quarter - 1) * 3))

        var amount = quarter <= 3 ? 0 : value

        this.chartOptions.series[2].data.push([date.getTime(), amount])
        quarter++
      }
    },
    setDepreciationData(value) {
      this.chartOptions.series[1].data = []

      var quarter = 1
      var startDate = this.project.connectionDate
      var startMonth = startDate.getMonth()

      var year1MACRS = this.macrsTable[1]
      var year2MACRS = this.macrsTable[1] + this.macrsTable[2]
      var year3MACRS = this.macrsTable[1] + this.macrsTable[2] + this.macrsTable[3]
      var year4MACRS = this.macrsTable[1] + this.macrsTable[2] + this.macrsTable[3] + this.macrsTable[4]
      var year5MACRS = this.macrsTable[1] + this.macrsTable[2] + this.macrsTable[3] + this.macrsTable[4] + this.macrsTable[5]
      var year6MACRS = this.macrsTable[1] + this.macrsTable[2] + this.macrsTable[3] + this.macrsTable[4] + this.macrsTable[5] + this.macrsTable[6]

      while (quarter <= 80) {
        var date = new Date(startDate)
        date.setHours(2)
        date.setMonth(startMonth + ((quarter - 1) * 3))

        var amount

        if (this.bonusDepreciation) {
          amount = quarter <= 3 ? 0 : value
        } else if (quarter <= 3) {
          amount = 0
        } else if (quarter <= 7) {
          amount = value * year1MACRS
        } else if (quarter <= 11) {
          amount = value * year2MACRS
        } else if (quarter <= 15) {
          amount = value * year3MACRS
        } else if (quarter <= 19) {
          amount = value * year4MACRS
        } else if (quarter <= 23) {
          amount = value * year5MACRS
        } else {
          amount = value * year6MACRS
        }

        this.chartOptions.series[1].data.push([date.getTime(), amount])
        quarter++
      }
    },
  },
}
</script>

<style scoped>
.metric >>> .v-chip__content{
  width: 100%;
}
.metric >>> .label {
  width: 140px;
  display: inline-block;
}
</style>
