<template>
  <span>
    <v-tooltip :top="top" :right="right" :bottom="bottom" :left="left" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          x-small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small class="text--disabled">
            {{ icons.mdiHelpCircle }}
          </v-icon>
        </v-btn>
      </template>
      <slot></slot>
    </v-tooltip>
  </span>
</template>

<script>
import { mdiHelpCircle } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiHelpCircle,
      },
    }
  },
  props: {
    top: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
